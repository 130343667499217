export const environment = {
  production: true,
  clientIdGateway: 'ddcab6bd-2cd8-4f18-811d-18b2cd0c1351',
  redirectURL: 'https://app-staging.cadostore.com/',
  localApi: false,
  authentication: true,
  versionApi: 'v1',
  urlSiteVitrine: 'https://cadostore.front.staging.cloud.bimpli.com/',
  urlComiteo: 'https://e.cadostore.com/sso',
  googleCaptchaPublicKey: '6LdVAiMiAAAAABG2s_NRkh9K9FU5Q4qQuT73gTSz',
  environment: 'QUA',
  applicationId: 'SiteCadoStore',
  pageSizeVar: '10',
  icVersion: '12238512180',
  icScmBranch: 'staging',
  icScmTag: 'v1.2.3',
  switchPayPlug: '1',
  urlWebFinanceur: 'https://entreprise.bimpli-preprod.com/',
  gtmId: 'GTM-NKNVC73J',
  urlWebBeneficiaire: 'https://collaborateur.bimpli-preprod.com/',
  sudoHashedPassword: '403E0BDB56E1100D123CDE057D317A65F88AF1C856D4C0B5DF80DF9544484D5C05DFDF1A0512604363A68F490C8E04D2143998368F420EB2747CC919E2841468',
};
